import { Injectable } from '@angular/core';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NbAclService} from "@nebular/security";

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard {
  protected roles: any;
  protected authenticated: any;
  protected groups: any;
  public activeGroup: any;
  public userInfo: any;

  constructor(protected router: Router, protected keycloakService: KeycloakService, public nbAclService: NbAclService) {
    super(router, keycloakService);
    this.keycloakService.getKeycloakInstance().loadUserInfo().then((userInfo) => {
        this.userInfo = userInfo;
    });

    if (this.keycloakService.getKeycloakInstance().tokenParsed != undefined && this.keycloakService.getKeycloakInstance().tokenParsed['groups'] !== undefined) {
        this.groups = this.keycloakService.getKeycloakInstance().tokenParsed['groups'];
    } else {
        this.keycloakService.getKeycloakInstance().tokenParsed['groups'] =  ['/teacher'];
        this.groups = this.keycloakService.getKeycloakInstance().tokenParsed['groups'];
    }
    this.activeGroup = this.groups[0] !== undefined ? this.groups[0]: 'teacher'
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!this.authenticated) {
        await this.keycloakService.login({
          redirectUri: window.location.origin + state.url
        });
      }
      const requiredRoles = route.data.roles;
      let granted: boolean = false;
      if (!requiredRoles || requiredRoles.length === 0) {
        granted = true;
      } else {
        for (const requiredRole of requiredRoles) {
          if (this.groups.includes(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
      }
      if (granted) {
        let permissions = {};
        this.keycloakService.getKeycloakInstance().realmAccess.roles.forEach((item, index) => {
          if (item.indexOf('ac:') > -1) {
            const permission = item.split(':');
            if (permissions.hasOwnProperty(permission[2])) {
              const existing = permissions[permission[2]];
              existing.push(permission[1]);
              permissions[permission[2]] = existing;
            } else {
              permissions[permission[2]] = [permission[1]];
            }
          }
        });
        console.log('permissions', permissions);
        this.nbAclService.setAccessControl( { [this.activeGroup]: permissions});
      } else {
        this.nbAclService.setAccessControl({});
        await this.router.navigate(['pages/miscellaneous/403']);
      }
      resolve(granted);
    });
  }


  public getGroup(): string {
    if (this.groups !== undefined && this.groups.indexOf('manager') > -1) {
      if (this.groups.indexOf('administrator') > -1) return 'administrator';
      else if (this.groups.indexOf('moderator') > -1) return 'moderator';
      else if (this.groups.indexOf('ce') > -1) return 'ce';
      else if (this.groups.indexOf('sg') > -1) return 'sg';
      else if (this.groups.indexOf('teacher') > -1) return 'teacher';
      else return 'teacher';
    }
    return 'teacher';
  }

  public isAdministrator(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('administrator') > -1) {
      return true;
    }
    return false;
  }

  public isModerator(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('moderator') > -1) {
      return true;
    }
    return false;
  }

  public isCE(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('ce') > -1) {
      return true;
    }
    return false;
  }

  public isSG(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('sg') > -1) {
      return true;
    }
    return false;
  }

  public isTeacher(): boolean {
    if (this.groups !== undefined && this.groups.indexOf('teacher') > -1) {
      return true;
    }
    return false;
  }

}
